import React from "react"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

export default () => (
  <Layout>
    <SEO title="Frequently Asked Questions" meta={["security"]} />
    <p>Coming Soon...</p>
  </Layout>
)
